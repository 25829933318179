import { Grants } from '@spec/Grants';
import {
    EditPersonalEventRequest,
    MeResponse,
    SabbaticalLeave,
    Talent,
    UpdateMeRequest,
} from '@spec/Talent';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { ReactNode, createContext, useContext } from 'react';
import { ApplicationError } from '../Errors';
import { useGateway } from '../components/Context';
import { WaitQuery } from '../components/WaitLoading';
import { blobToBase64 } from '../stores/Gateway';
import { queryKey } from './queryKey';
import { invalidateTalents } from './talent';

const useMe = (initialData: MeResponse) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.me],
        queryFn: () => gateway.me(),
        initialData,
    });
};

const MeContext = createContext(
    {} as {
        me: Talent;
        grants: Grants;
        sabbaticalLeaves: SabbaticalLeave[];
        nextSabbaticalLeave: Date | null;
    }
);
export const useMeContext = () => useContext(MeContext);
export const MeContextProvider: React.FC<{
    response: MeResponse;
    children: ReactNode;
}> = (props) => {
    const maybeMe = useMe(props.response);
    return (
        <WaitQuery query={maybeMe}>
            {({ data }) => (
                <MeContext.Provider
                    value={{
                        me: data.talent,
                        grants: data.grants,
                        sabbaticalLeaves: data.sabbaticalLeaves,
                        nextSabbaticalLeave: data.nextSabbaticalLeave,
                    }}
                >
                    {props.children}
                </MeContext.Provider>
            )}
        </WaitQuery>
    );
};

export const invalidateMe = async (queryClient: QueryClient) => {
    await Promise.all([
        invalidateTalents(queryClient),
        queryClient.invalidateQueries({ queryKey: [queryKey.me] }),
    ]);
};

export const useUpdateMe = () => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (args: UpdateMeRequest) => gateway.put('/me', args),
        onSuccess: () => invalidateMe(queryClient),
    });
};

export const useUploadProfileImage = () => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (blob: Blob) => {
            const image = await blobToBase64(blob);
            if (typeof image !== 'string') {
                throw new ApplicationError('unknown image type');
            }
            return gateway.post('/me/profile-image', { image });
        },
        onSuccess: () => invalidateMe(queryClient),
    });
};

export const useAddPersonalEvent = () => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (args: EditPersonalEventRequest) => {
            await gateway.post('/me/personal-event', args);
        },
        onSuccess: () => invalidateMe(queryClient),
    });
};

export const useEditPersonalEvent = (personalEventId: number) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (args: EditPersonalEventRequest) => {
            await gateway.put(`/me/personal-event/${personalEventId}`, args);
        },
        onSuccess: () => invalidateMe(queryClient),
    });
};

export const useDeletePersonalEvent = (personalEventId: number) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            await gateway.delete(`/me/personal-event/${personalEventId}`);
        },
        onSuccess: () => invalidateMe(queryClient),
    });
};
