import {
    Box,
    Checkbox,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { TodoAssignment } from '@spec/Todo';
import React, { useState } from 'react';
import { DisabledTableRow } from '../../../../../Theme';
import { isAvailableTalent } from '../../../../../domains/Talent';
import { useEmptyForm } from '../../../../../lib/Form';
import { useAssignTodoItems } from '../../../../../queries/todoAdmin';
import { useTalentsContext, useTeamsContext } from '../../../../Context';
import { Pager, sliceItems } from '../../../../Pager';
import { comparer, useTableSorter } from '../../../../SortableTable';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../../itemList/Context';
import {
    AssigneeFilterForm,
    FilterCondition,
    filterTalents,
    useFilterCondition,
} from '../AssigneeFilterForm';
import { useTodoItemContext } from '../Context';
import { Assignee, toAssignee } from '../Contract';

export const AssignDialog: React.FC = () => {
    const theme = useTheme();
    const { closeDialog } = useDialogContext();
    const { item } = useTodoItemContext();
    const form = useEmptyForm();
    const mutation = useAssignTodoItems(item.id);
    const [checked, setChecked] = useState<Set<string>>(new Set());
    const checkTalent = (employeeCode: string) => {
        setChecked((before) => {
            if (before.has(employeeCode)) {
                before.delete(employeeCode);
            } else {
                before.add(employeeCode);
            }
            return new Set(before);
        });
    };
    const [condition, updateCondition] = useFilterCondition();
    return (
        <>
            <DialogTitle>「{item.title}」の対象者追加</DialogTitle>
            <DialogContent
                sx={{
                    height: '100vh',
                    backgroundColor: theme.palette.background.default,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            >
                <Box mt={2}>
                    <AssigneeFilterForm condition={condition} updateCondition={updateCondition} />
                </Box>
                <TalentTable
                    checked={checked}
                    setChecked={setChecked}
                    checkTalent={checkTalent}
                    condition={condition}
                />
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="チェックした人を対象に追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="追加に失敗しました"
                onSubmit={() => mutation.mutateAsync({ employeeCodes: [...checked] })}
                closeDialog={closeDialog}
            />
        </>
    );
};

const TalentTable: React.FC<{
    checked: Set<string>;
    setChecked: (v: Set<string>) => void;
    checkTalent: (v: string) => void;
    condition: FilterCondition;
}> = (props) => {
    const { talents } = useTalentsContext();
    const { teams } = useTeamsContext();
    const { assignments } = useTodoItemContext();
    const availableTalents = talents.filter(isAvailableTalent);
    const assignees = new Map(assignments.map((v) => [v.talentId, v]));

    const { sortKey, direction, SortHeader } = useTableSorter<Assignee>('employeeCode');

    const candidates = filterTalents(props.condition, availableTalents, teams, assignments)
        .map((t) => toAssignee(t, teams, assignees.get(t.id)))
        .sort((a, b) => comparer(a, b, sortKey, 'employeeCode', direction));

    const [page, setPage] = useState(1);
    const itemsPerPage = 20;
    const slicedItems = sliceItems(candidates, page, itemsPerPage);
    if (slicedItems.length === 0) {
        return (
            <Box mt={4} textAlign="center">
                <Typography color="error">条件に合致する対象者はいません</Typography>
            </Box>
        );
    }
    return (
        <Box>
            <Box mt={2}>
                <Pager
                    current={page}
                    setPage={setPage}
                    amount={candidates.length}
                    perItems={itemsPerPage}
                />
            </Box>
            <Box mt={1} mb={0.5} mx={2}>
                <Typography variant="body2" color="primary">
                    {props.checked.size}件選択中です
                </Typography>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                size="small"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        props.setChecked(
                                            new Set(
                                                candidates
                                                    .filter((v) => !assignees.has(v.id))
                                                    .map((v) => v.employeeCode)
                                            )
                                        );
                                    } else {
                                        props.setChecked(new Set());
                                    }
                                }}
                            />
                        </TableCell>
                        <SortHeader sortKey="employeeCode">社員番号</SortHeader>
                        <SortHeader sortKey="name">氏名</SortHeader>
                        <SortHeader sortKey="joinedAt">入社日</SortHeader>
                        <SortHeader sortKey="isNewGraduate">入社区分</SortHeader>
                        <SortHeader sortKey="employmentStatus">雇用形態</SortHeader>
                        <SortHeader sortKey="teamName">所属</SortHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {slicedItems.map((v) => (
                        <TalentRow
                            key={v.id}
                            assignee={v}
                            assignment={assignees.get(v.id) ?? null}
                            checked={props.checked}
                            checkTalent={props.checkTalent}
                        />
                    ))}
                </TableBody>
            </Table>
            <Box mt={2}>
                <Pager
                    current={page}
                    setPage={setPage}
                    amount={candidates.length}
                    perItems={itemsPerPage}
                />
            </Box>
        </Box>
    );
};

const TalentRow: React.FC<{
    assignee: Assignee;
    assignment: TodoAssignment | null;
    checked: Set<string>;
    checkTalent: (v: string) => void;
}> = (props) => {
    const t = props.assignee;
    const disabled = props.assignment !== null;
    const RowComponent = disabled ? DisabledTableRow : TableRow;
    return (
        <Tooltip
            title="既に対象になっています"
            placement="top"
            followCursor={true}
            disableHoverListener={!disabled}
        >
            <RowComponent>
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled={disabled}
                        size="small"
                        checked={props.checked.has(t.employeeCode)}
                        onClick={() => props.checkTalent(t.employeeCode)}
                    />
                </TableCell>
                <TableCell>{t.employeeCode}</TableCell>
                <TableCell>{t.name}</TableCell>
                <TableCell>{t.joinedAt.format('YYYY-MM-DD')}</TableCell>
                <TableCell>{t.isNewGraduate ? '新卒' : '中途'}</TableCell>
                <TableCell>{t.employmentStatus}</TableCell>
                <TableCell>{t.teamName}</TableCell>
            </RowComponent>
        </Tooltip>
    );
};
