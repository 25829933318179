import { createContext, ReactNode, useContext } from 'react';
import { DialogContextProvider, useGenericDialogContext } from '../Context';
import { SignUpForm, useSignUpForm } from './SignUpForm';

export type DialogMode = 'Confirm';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const SignUpFormContext = createContext({} as { form: SignUpForm });
const SignUpFormContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const form = useSignUpForm();
    return (
        <SignUpFormContext.Provider value={{ form }}>{props.children}</SignUpFormContext.Provider>
    );
};
export const useSignUpFormContext = () => useContext(SignUpFormContext);

export const ContextProvider: React.FC<{
    children: ReactNode;
}> = (props) => {
    return (
        <DialogContextProvider>
            <SignUpFormContextProvider>{props.children}</SignUpFormContextProvider>
        </DialogContextProvider>
    );
};
