import { DialogContent, DialogTitle, Stack, Typography, type TypographyProps } from '@mui/material';
import React from 'react';
import { useGateway } from '../Context';
import { FlexBox } from '../FlexBox';
import { DialogActionButtons } from '../StableDialog';
import { useDialogContext, useSignUpFormContext } from './Context';

export const ConfirmDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { form } = useSignUpFormContext();
    const gateway = useGateway();
    return (
        <>
            <DialogTitle>以下の内容でアカウント登録しますか？</DialogTitle>
            <DialogContent>
                <Stack spacing={1} mx={4}>
                    <FlexBox>
                        <Label>氏名</Label>
                        <Typography>
                            {form.lastName.value} {form.firstName.value}
                        </Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>よみがな</Label>
                        <Typography>
                            {form.lastNameKana.value} {form.firstNameKana.value}
                        </Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>英語表記</Label>
                        <Typography>{form.romanName.value}</Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>SlackID</Label>
                        <Typography>{form.slackName.value}</Typography>
                    </FlexBox>
                </Stack>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容で登録する"
                cancelLabel="入力画面に戻る"
                errorMessage="登録に失敗しました"
                onSubmit={() =>
                    gateway
                        .signUp(
                            form.lastName.value,
                            form.firstName.value,
                            form.lastNameKana.value,
                            form.firstNameKana.value,
                            form.romanName.value,
                            form.slackName.value
                        )
                        .then(() => {
                            window.location.reload();
                        })
                }
                closeDialog={() => {
                    // quick hack to reset previous error
                    form.failed = false;
                    closeDialog();
                }}
            />
        </>
    );
};

const Label = (props: TypographyProps) => <Typography sx={{ width: '6rem' }} {...props} />;
