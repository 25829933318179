import { Autocomplete, CircularProgress, TextField, Typography } from '@mui/material';
import { FormText } from '../../lib/Form';
import { queryToArray } from '../../queries';
import { useAppInvitedSlackChannels } from '../../queries/slack';
import { FlexBox } from '../FlexBox';
import { ArticleCacheSetter } from './Contract';

export const TAGS_SEPARATOR = '\t';

export const TagSelector: React.FC<{
    formText: FormText;
    setCache: ArticleCacheSetter;
    existingTags: string[];
}> = (props) => {
    const current = props.formText.value.split(TAGS_SEPARATOR).filter((v) => v !== '');
    const maybeChannels = useAppInvitedSlackChannels();
    const channelNames = queryToArray(maybeChannels).map((v) => v.name);
    const options = [...new Set([...channelNames, ...props.existingTags])].sort();
    return (
        <Autocomplete
            fullWidth
            multiple
            filterSelectedOptions
            loading={maybeChannels.isFetching}
            loadingText={
                <FlexBox gap={1}>
                    <CircularProgress color="secondary" size={20} />
                    <Typography variant="body2">読み込み中</Typography>
                </FlexBox>
            }
            options={options}
            noOptionsText={
                <Typography variant="body2" color="error">
                    候補が見つかりません
                </Typography>
            }
            renderOption={(p, v) => (
                <li {...p} key={v}>
                    {v}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Slackチャンネルで指定できます"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: params.InputProps.startAdornment,
                    }}
                />
            )}
            value={current}
            onChange={(_e, v) => {
                const value = v.join(TAGS_SEPARATOR);
                props.setCache('tags', value);
                props.formText.setValue(value);
            }}
        />
    );
};
