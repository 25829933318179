import {
    CircleMembersResponse,
    CircleTermsResponse,
    CirclesResponse,
    EditCircleRequest,
    EditCircleTermRequest,
} from '@spec/Circle';
import { MutationFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useGateway } from '../components/Context';
import { queryKey } from './queryKey';

export const useCircleTerms = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.circleTerms],
        queryFn: () => gateway.get<CircleTermsResponse>('/circle/terms').then((res) => res.terms),
    });
};

export const useCircles = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.circles],
        queryFn: () => gateway.get<CirclesResponse>('/circles').then((res) => res.circles),
    });
};

export const useCircleMembers = (termId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.circleMembers, termId],
        queryFn: () =>
            gateway
                .get<CircleMembersResponse>(`/circle/terms/${termId}/members`)
                .then((res) => res.members),
    });
};

const createUseMutation =
    (queryKey: string[]) =>
    <TVariables = void>(mutationFn: MutationFunction<void, TVariables>) => {
        const queryClient = useQueryClient();
        return useMutation<void, Error, TVariables>({
            mutationFn,
            onSuccess: () => queryClient.invalidateQueries({ queryKey }),
        });
    };

const useCircleMutation = createUseMutation([queryKey.circles]);

export const useAddCircle = () => {
    const gateway = useGateway();
    return useCircleMutation((args: EditCircleRequest) => gateway.post('/circles', args));
};

export const useUpdateCircle = (circleId: number) => {
    const gateway = useGateway();
    return useCircleMutation((args: EditCircleRequest) =>
        gateway.put(`/circles/${circleId}`, args)
    );
};

export const useDeleteCircle = (circleId: number) => {
    const gateway = useGateway();
    return useCircleMutation(() => gateway.delete(`/circles/${circleId}`));
};

const useTermMutation = createUseMutation([queryKey.circleTerms]);

export const useAddCircleTerm = () => {
    const gateway = useGateway();
    return useTermMutation((args: EditCircleTermRequest) => gateway.post('/circle/terms', args));
};

export const useUpdateCircleTerm = (termId: number) => {
    const gateway = useGateway();
    return useTermMutation((args: EditCircleTermRequest) =>
        gateway.put(`/circle/terms/${termId}`, args)
    );
};

export const useDeleteCircleTerm = (termId: number) => {
    const gateway = useGateway();
    return useTermMutation(() => gateway.delete(`/circle/terms/${termId}`));
};
