import {
    ApplicableSurveysResponse,
    SurveyGroupOperatePermissionsResponse,
    SurveyMemosResponse,
    SurveyPeriod,
    type IsAppliedSurveyResponse,
    type SurveyApplyingRequest,
    type SurveyReadPermissionsResponse,
    type SurveyReportResponse,
    type SurveysResponse,
} from '@spec/Survey';
import { TalentId } from '@spec/Talent';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useGateway } from '../components/Context';
import { useSurveysContext } from '../components/survey/Context';
import { isReadableSurvey } from '../domains/Survey';
import { Gateway } from '../stores/Gateway';
import { useMeContext } from './me';
import { queryKey } from './queryKey';

export const useSurveys = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.surveys],
        queryFn: () => gateway.get<SurveysResponse>('/surveys'),
    });
};

export const useSurveyPeriodReport = (groupId: number, periodId: number) => {
    const gateway = useGateway();
    const { surveys } = useSurveysContext();
    const { grants } = useMeContext();
    return useQuery({
        queryKey: [queryKey.surveyPeriod, groupId, periodId],
        queryFn: () =>
            Promise.all(
                surveys
                    .filter((v) => v.surveyGroupId === groupId)
                    .filter((v) => isReadableSurvey(v, grants.survey))
                    .map((v) =>
                        gateway
                            .get<SurveyReportResponse>(`/surveys/${v.id}/report/period/${periodId}`)
                            .then((res) => res.responses)
                    )
            ).then((v) => v.flat()),
    });
};

export const useSurveyTalentReport = (groupId: number, employeeCode: string) => {
    const gateway = useGateway();
    const { surveys } = useSurveysContext();
    const { grants } = useMeContext();
    return useQuery({
        queryKey: [queryKey.surveyTalent, groupId, employeeCode],
        queryFn: () =>
            Promise.allSettled(
                surveys
                    .filter((v) => v.surveyGroupId === groupId)
                    .filter((v) => isReadableSurvey(v, grants.survey))
                    .map((v) =>
                        gateway
                            .get<SurveyReportResponse>(
                                `/surveys/${v.id}/report/talent/${employeeCode}`
                            )
                            .then((res) => res.responses)
                    )
            ).then((v) => v.map((v) => (v.status === 'fulfilled' ? v.value : [])).flat()),
    });
};

export const useApplySurvey = (surveyGroupId: number) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation<void, Error, SurveyApplyingRequest>({
        mutationFn: (args) => gateway.post(`/surveys/groups/${surveyGroupId}/apply`, args),
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: [queryKey.surveys, 'isAppliedGroup', surveyGroupId],
            }),
    });
};

export const useIsAppliedSurveyGroup = (surveyGroupId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.surveys, 'isAppliedGroup', surveyGroupId],
        queryFn: () =>
            gateway
                .get<IsAppliedSurveyResponse>(`/surveys/groups/${surveyGroupId}/apply`)
                .then((res) => res.applied),
    });
};

export const useSurveyReadPermissions = (surveyId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.surveys, 'readPermissions', surveyId],
        queryFn: () =>
            gateway
                .get<SurveyReadPermissionsResponse>(`/surveys/grants/read/${surveyId}`)
                .then((res) => res.permissions),
    });
};

export const useApplicableSurveys = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.surveys, 'applicable'],
        queryFn: () =>
            gateway
                .get<ApplicableSurveysResponse>('/surveys/applicable')
                .then((res) => res.surveys),
    });
};

export const useSurveyGroupOperatePermissions = (surveyGroupId: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.surveys, 'groupOperatePermissions', surveyGroupId],
        queryFn: () =>
            gateway
                .get<SurveyGroupOperatePermissionsResponse>(
                    `/surveys/grants/operate/${surveyGroupId}`
                )
                .then((res) => res.talentIds),
    });
};

export const useSurveyMemos = (period: SurveyPeriod) => {
    const gateway = useGateway();
    const { grants } = useMeContext();
    return useQuery({
        queryKey: [queryKey.surveys, 'memos', period.id],
        queryFn: () => {
            if (!grants.survey.operableSurveyGroups.includes(period.surveyGroupId)) {
                return Promise.resolve([]);
            }
            return gateway
                .get<SurveyMemosResponse>(`/surveys/periods/${period.id}/memos`)
                .then((res) => res.memos);
        },
    });
};

export const postMemo = (
    gateway: Gateway,
    queryClient: QueryClient,
    periodId: number,
    talentId: TalentId,
    content: string
) =>
    gateway
        .post(`/surveys/periods/${periodId}/memos`, { talentId, content })
        .then(() =>
            queryClient.invalidateQueries({ queryKey: [queryKey.surveys, 'memos', periodId] })
        );
