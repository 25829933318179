import { Box, Button, Divider } from '@mui/material';
import { NO_GRADE_SHORT_TEXT, hiraToKana } from '../../domains/Talent';
import {
    Form,
    useForm,
    useFormBoolean,
    useFormDate,
    useFormNumber,
    useFormText,
} from '../../lib/Form';
import { required } from '../../lib/Form/Validators';
import { NOT_SELECTED_WORKPLACE } from '../WorkplaceSelector';
import { useDialogContext, useRegisterFormContext } from './Context';
import { EmploymentForm } from './EmploymentForm';
import { PersonalityForm } from './PersonalityForm';

export interface RegisterElements {
    email: string;
    employeeCode: string;
    joinedAt: Date | null;
    isNewGraduate: boolean;
    employmentStatus: string;
    gradeText: string;
    teamId: number | null;
    position: string;
    lastName: string;
    firstName: string;
    lastNameKana: string;
    firstNameKana: string;
    romanName: string;
    workplace: string;
}

export const useRegisterForm = () =>
    useForm<RegisterElements>({
        email: useFormText('', [required]),
        employeeCode: useFormText('', [required]),
        joinedAt: useFormDate(null, [required]),
        isNewGraduate: useFormBoolean(false),
        employmentStatus: useFormText('', [required]),
        gradeText: useFormText(NO_GRADE_SHORT_TEXT),
        teamId: useFormNumber(null, [required]),
        position: useFormText(''),
        lastName: useFormText('', [required]),
        firstName: useFormText('', [required]),
        lastNameKana: useFormText('', [required], { onCompositionEnd: [hiraToKana] }),
        firstNameKana: useFormText('', [required], { onCompositionEnd: [hiraToKana] }),
        romanName: useFormText('', [required]),
        workplace: useFormText(NOT_SELECTED_WORKPLACE),
    });
export type RegisterForm = Form<RegisterElements>;

export const RegisterFormContent: React.FC = () => {
    const { setDialogMode } = useDialogContext();
    const { form } = useRegisterFormContext();
    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Box mx={4} mt={4}>
                <EmploymentForm />
                <Box my={2}>
                    <Divider />
                </Box>
                <PersonalityForm />
                <Box mt={5} mb={2} mx={20}>
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="medium"
                        disabled={!form.canSubmit}
                        onClick={() => {
                            setDialogMode('Confirm');
                        }}
                    >
                        登録する
                    </Button>
                </Box>
            </Box>
        </form>
    );
};
