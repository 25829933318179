import { TeamId } from './Organization';
import { MySurveyReadPermissions } from './Survey';
import { TalentId } from './Talent';

export interface GrantsResponse {
    grants: Grant[];
}

export interface GrantedResponse {
    granted: boolean;
}

export interface Grant {
    talentId: TalentId;
    privilege: Privilege;
    grantedAt: Date;
}

// Import this module relatively ('../../@spec/Grants')
// when you would like to use concrete implementations
export const Privileges = [
    'EDIT_TALENT',
    'READ_LOGS',
    'SHOW_LEAVED_TALENT',
    'GRANT_PRIVILEGE',
    'SHOW_FULL_PERSONALITY',
    'EDIT_WORKPLACES',
    'MANAGE_CIRCLE',
    'MANAGE_VALUE_FEEDBACK',
    'UPLOAD_PROFILE_IMAGE',
    'MANAGE_TODO',
    'DOWNLOAD_CSV',
    'MANAGE_SURVEY',
    'MANAGE_DIVE',
] as const;
export type Privilege = (typeof Privileges)[number];
export const isPrivilege = (v: unknown): v is Privilege =>
    typeof v === 'string' && new Set<string>(Privileges).has(v);

export type Grants = {
    editTalent: boolean;
    readLogs: boolean;
    showLeavedTalent: boolean;
    grantPrivilege: boolean;
    showFullPersonality: boolean;
    editWorkplaces: boolean;
    manageCircle: boolean;
    manageValueFeedback: boolean;
    uploadProfileImage: boolean;
    manageTodo: boolean;
    downloadCsv: boolean;
    manageSurvey: boolean;
    manageDive: boolean;
    survey: SurveyPermissions;
    valueFeedback: ValueFeedbackPermissions;
};

export interface GrantPrivilegeRequest {
    talentId: TalentId;
    privilege: Privilege;
}

export interface SurveyPermissions {
    surveyReadPermissions: MySurveyReadPermissions;
    operableSurveyGroups: number[];
}

export interface ValueFeedbackPermissions {
    readableTeamIds: TeamId[];
}
