import { Employment, Transfer, type Secondment } from '@spec/Talent';
import React, { createContext, useContext, useState, type PropsWithChildren } from 'react';
import { StateValue, useStateValue } from '../../../../lib/Context';
import { DialogContextProvider, useGenericDialogContext } from '../../../Context';
import { EditTalentProps } from './Contract';

export type DialogMode =
    | 'AddTransfer'
    | 'EditTransfer'
    | 'DeleteTransfer'
    | 'AddEmployment'
    | 'EditEmployment'
    | 'DeleteEmployment'
    | 'AddSecondment'
    | 'EditSecondment'
    | 'DeleteSecondment';
export const useDialogContext = useGenericDialogContext<DialogMode>;

export const TransferHistoryContext = createContext(
    {} as {
        transfer: StateValue<Transfer | null>;
    }
);
const TransferHistoryContextProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
    const transfer = useStateValue<Transfer | null>(null);
    return (
        <TransferHistoryContext.Provider value={{ transfer }}>
            {props.children}
        </TransferHistoryContext.Provider>
    );
};

export const EmploymentContext = createContext(
    {} as {
        employment: StateValue<Employment | null>;
    }
);
const EmploymentContextProvider: React.FC<React.PropsWithChildren<unknown>> = (props) => {
    const employment = useStateValue<Employment | null>(null);
    return (
        <EmploymentContext.Provider
            value={{
                employment,
            }}
        >
            {props.children}
        </EmploymentContext.Provider>
    );
};

export const EditTalentContext = createContext(
    {} as {
        employeeCode: string;
    }
);
const EditTalentContextProvider: React.FC<React.PropsWithChildren<EditTalentProps>> = (props) => {
    return (
        <EditTalentContext.Provider
            value={{
                employeeCode: props.talent.employment.employeeCode,
            }}
        >
            {props.children}
        </EditTalentContext.Provider>
    );
};
export const useEditTalentContext = () => useContext(EditTalentContext);

const SecondmentContext = createContext(
    {} as {
        secondment: Secondment | null;
        setSecondment: (secondment: Secondment | null) => void;
    }
);
const SecondmentContextProvider = (props: PropsWithChildren) => {
    const [secondment, setSecondment] = useState<Secondment | null>(null);
    return (
        <SecondmentContext.Provider value={{ secondment, setSecondment }}>
            {props.children}
        </SecondmentContext.Provider>
    );
};
export const useSecondmentContext = () => useContext(SecondmentContext);

export const ContextProvider: React.FC<React.PropsWithChildren<EditTalentProps>> = (props) => {
    return (
        <EditTalentContextProvider {...props}>
            <DialogContextProvider>
                <TransferHistoryContextProvider>
                    <EmploymentContextProvider>
                        <SecondmentContextProvider>{props.children}</SecondmentContextProvider>
                    </EmploymentContextProvider>
                </TransferHistoryContextProvider>
            </DialogContextProvider>
        </EditTalentContextProvider>
    );
};
