import type { LogsResponse } from '@spec/Logs';
import { useQuery } from '@tanstack/react-query';
import { useGateway } from '../components/Context';
import { queryKey } from './queryKey';

export const useOperationLogs = (year: number, month: number) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.operationLogs, year, month],
        queryFn: () =>
            gateway.get<LogsResponse>(`/logs/monthly/${year}/${month}`).then((res) => res.logs),
    });
};
