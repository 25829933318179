import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { ApplicationError } from '../../../../../Errors';
import { fullName } from '../../../../../domains/Talent';
import { findById } from '../../../../../lib/ArrayUtils';
import { useEmptyForm } from '../../../../../lib/Form';
import { useRevokeGroupOperate } from '../../../../../queries/surveyAdmin';
import { useTalentsContext } from '../../../../Context';
import { FlexBox } from '../../../../FlexBox';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { TalentAvatar } from '../../../../TalentAvatar';
import { useSurveysContext } from '../../../Context';
import { useDialogContext, useTalentIdContext } from '../Context';

export const RevokePermissionDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { groupId } = useParams();
    const { surveyGroups } = useSurveysContext();
    const group = findById(Number(groupId), surveyGroups);

    const { talents } = useTalentsContext();
    const { talentId } = useTalentIdContext();
    if (talentId === null) {
        throw new ApplicationError('unknown talentId');
    }
    const talent = findById(talentId, talents);
    const form = useEmptyForm();
    const mutation = useRevokeGroupOperate(group.id);
    return (
        <>
            <DialogTitle>{group.name}のメモ権限を削除する</DialogTitle>
            <DialogContent>
                <DialogRow label="対象者">
                    <FlexBox gap={1}>
                        <TalentAvatar size="small" talent={talent} />
                        <Typography variant="body2">{talent.employment.employeeCode}</Typography>
                        <Typography variant="body2">{fullName(talent)}</Typography>
                    </FlexBox>
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="このメモ権限を削除する"
                cancelLabel="削除せずに閉じる"
                errorMessage="メモ権限の削除に失敗しました"
                onSubmit={() => mutation.mutateAsync(talent.id)}
                closeDialog={closeDialog}
            />
        </>
    );
};
