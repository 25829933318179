import { createContext, ReactNode, useContext } from 'react';
import { DialogContextProvider, useGenericDialogContext } from '../Context';
import { RegisterForm, useRegisterForm } from './RegisterForm';

export type DialogMode = 'Confirm';
export const useDialogContext = useGenericDialogContext<DialogMode>;

const RegisterFormContext = createContext({} as { form: RegisterForm });
const RegisterFormContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const form = useRegisterForm();
    return (
        <RegisterFormContext.Provider value={{ form }}>
            {props.children}
        </RegisterFormContext.Provider>
    );
};
export const useRegisterFormContext = () => useContext(RegisterFormContext);

export const ContextProvider: React.FC<{
    children: ReactNode;
}> = (props) => {
    return (
        <DialogContextProvider>
            <RegisterFormContextProvider>{props.children}</RegisterFormContextProvider>
        </DialogContextProvider>
    );
};
