import {
    Box,
    Button,
    Paper,
    Stack,
    Typography,
    useTheme,
    type TypographyProps,
} from '@mui/material';
import React from 'react';
import { Form, FormTextField, useForm, useFormText } from '../../lib/Form';
import { hiraToKana } from '../../lib/Form/Filters';
import { required } from '../../lib/Form/Validators';
import { useGateway } from '../Context';
import { FlexBox } from '../FlexBox';
import { HistoryIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { useDialogContext, useSignUpFormContext } from './Context';

export interface Elements {
    lastName: string;
    firstName: string;
    lastNameKana: string;
    firstNameKana: string;
    romanName: string;
    slackName: string;
}

export type SignUpForm = Form<Elements>;

export const useSignUpForm = () =>
    useForm<Elements>({
        lastName: useFormText('', [required]),
        firstName: useFormText('', [required]),
        lastNameKana: useFormText('', [required], {
            onCompositionEnd: [hiraToKana],
        }),
        firstNameKana: useFormText('', [required], {
            onCompositionEnd: [hiraToKana],
        }),
        romanName: useFormText('', [required]),
        slackName: useFormText('', [required]),
    });

export const SignUpFormContent: React.FC<React.PropsWithChildren<{ email: string }>> = (props) => {
    const theme = useTheme();
    const { setDialogMode } = useDialogContext();
    const { form } = useSignUpFormContext();
    const gateway = useGateway();
    return (
        <Box>
            <Paper
                sx={{
                    width: '800px',
                    margin: theme.spacing(0, 'auto'),
                    padding: theme.spacing(2),
                    background: theme.palette.background.default,
                }}
            >
                <PageTitle icon={HistoryIcon} title="ユーザー登録" />
                <Stack mt={2} spacing={0.5} textAlign="center">
                    <Typography>あなたのアカウント{props.email}は登録されていません。</Typography>
                    <Typography>
                        以下のフォームからテスト環境にアカウントを作成できます。
                    </Typography>
                    <Typography>
                        本番ではあらかじめ従業員の情報を提供してシステム稼働させるので、この機能はテスト環境専用のものです。
                    </Typography>
                </Stack>
                <Stack mt={2} spacing={0.5} textAlign="center">
                    <Typography>ここで入力する以外の情報はランダムに決定されます。</Typography>
                    <Typography>
                        「自分が誰か分かるように」を目的とした登録なので、必ずしも実名で記入する必要はありません。
                    </Typography>
                </Stack>
                <Stack mt={2} spacing={0.5} textAlign="center">
                    <Typography>
                        なお、テスト環境につき登録された情報は予告なくそれなりの頻度で削除されることをご了承ください。
                    </Typography>
                    <Typography>
                        「前にも登録したのにまた？」と感じたら、それはシステムが進歩しているのだと思ってご容赦ください。
                    </Typography>
                </Stack>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack mx={4} mt={4} spacing={1}>
                        <FlexBox>
                            <Label>姓</Label>
                            <Box flexGrow={1}>
                                <FormTextField formText={form.lastName} fullWidth autoFocus />
                            </Box>
                            <Label ml={3}>名</Label>
                            <Box flexGrow={1}>
                                <FormTextField formText={form.firstName} fullWidth />
                            </Box>
                        </FlexBox>
                        <FlexBox>
                            <Label>セイ</Label>
                            <Box flexGrow={1}>
                                <FormTextField formText={form.lastNameKana} fullWidth />
                            </Box>
                            <Label ml={3}>メイ</Label>
                            <Box flexGrow={1}>
                                <FormTextField formText={form.firstNameKana} fullWidth />
                            </Box>
                        </FlexBox>
                        <FlexBox>
                            <Label>英語名</Label>
                            <Box flexGrow={1}>
                                <FormTextField formText={form.romanName} fullWidth />
                            </Box>
                            <Label ml={3}>Slack名</Label>
                            <Box flexGrow={1}>
                                <FormTextField formText={form.slackName} fullWidth />
                            </Box>
                        </FlexBox>
                    </Stack>
                    <Box mt={4} mb={2} mx={30}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="medium"
                            disabled={!form.canSubmit}
                            onClick={() => setDialogMode('Confirm')}
                        >
                            登録する
                        </Button>
                    </Box>
                </form>
            </Paper>
            <Box my={6} textAlign="center">
                <Button size="medium" variant="outlined" onClick={() => gateway.signOut()}>
                    登録せずにログアウトする
                </Button>
            </Box>
        </Box>
    );
};

const Label = (props: TypographyProps) => <Typography sx={{ width: '4rem' }} {...props} />;
