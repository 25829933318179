import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    Typography,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { PostNotesArticleRequest } from '@spec/Notes';
import { useEffect, useState } from 'react';
import { FormCheckBox, FormTextField, useForm, useFormBoolean, useFormText } from '../../lib/Form';
import { trim } from '../../lib/Form/Filters';
import { LengthCounter } from '../../lib/Form/LengthCounter';
import { countBytes, maxBytes, maxLength, required } from '../../lib/Form/Validators';
import { useStorageToggle } from '../../lib/Storage';
import { useTenantContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { HelpTip } from '../HelpTip';
import { HelpOutlineIcon } from '../Icons';
import { ArticleMarkdown } from '../Markdown';
import { ArticleCacheSetter, ArticleForm } from './Contract';
import { useArticleCache } from './Hooks';
import { TAGS_SEPARATOR, TagSelector } from './TagSelector';
import { TiptapEditor } from './tiptap/TiptapEditor';

export const toPostNotesArticleRequest = (form: ArticleForm): PostNotesArticleRequest => {
    const x = form.serialize();
    return { ...x, tags: x.tags.split(TAGS_SEPARATOR).filter((v) => v !== '') };
};

const TITLE_MAX_LENGTH = 191;
const CONTENT_MAX_BYTES = 60000;

const PREVIEW_DEBOUNCE_DELAY = 500;

export const useArticleForm = (
    articleId: number | null,
    title: string,
    tags: string,
    content: string,
    isPinned: boolean
) => {
    const { tenant } = useTenantContext();
    const [cache, setCache] = useArticleCache(tenant, articleId, {
        isPinned,
        title,
        tags,
        content,
    });
    const form = useForm({
        isPinned: useFormBoolean(isPinned),
        title: useFormText(cache.title, [required, maxLength(TITLE_MAX_LENGTH)], {
            onBlur: [
                trim,
                (v) => {
                    setCache('title', v);
                    return v;
                },
            ],
        }),
        tags: useFormText(cache.tags),
        content: useFormText(cache.content, [required, maxBytes(CONTENT_MAX_BYTES)], {
            onBlur: [
                trim,
                (v) => {
                    setCache('content', v);
                    return v;
                },
            ],
        }),
    });
    useEffect(() => {
        setCache('isPinned', form.isPinned.value);
    }, [form.isPinned.value, setCache]);
    return [form, setCache] as const;
};

export const ArticleFormContent: React.FC<{
    form: ArticleForm;
    setCache: ArticleCacheSetter;
    existingTags?: string[];
}> = (props) => {
    const [wysiwyg, toggleWysiwyg] = useStorageToggle('notes:wysiwyg', true);
    const [openSlackHelp, setOpenSlackHelp] = useState(false);

    // fire onBlur event when the page is closed
    useEffect(() => {
        const handleBeforeUnload = () => {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const content = props.form.content.value;
    const [previewContent, setPreviewContent] = useState(content);
    useEffect(() => {
        const timerId = setTimeout(() => {
            setPreviewContent(content);
        }, PREVIEW_DEBOUNCE_DELAY);
        return () => {
            clearTimeout(timerId);
        };
    }, [content]);

    return (
        <Box>
            <SlackHelpDialog open={openSlackHelp} handleClose={() => setOpenSlackHelp(false)} />
            <Box ml={2} mr={6} display="flex" gap={4}>
                <Box flex="0 0 50%">
                    <FlexBox>
                        <FormCheckBox
                            formBoolean={props.form.isPinned}
                            name="isPinned"
                            label="この記事をピン留めする"
                        />
                        <HelpTip title="ピン留めした記事はプロフィール画面やあなたの記事一覧で常に先頭に固定表示されます" />
                    </FlexBox>
                    <Typography mt={1}>タイトル</Typography>
                    <FlexBox gap={1}>
                        <Box flexGrow={1}>
                            <FormTextField formText={props.form.title} autoFocus fullWidth />
                        </Box>
                        <LengthCounter text={props.form.title.value} maxLength={TITLE_MAX_LENGTH} />
                    </FlexBox>
                    <FlexBox mt={1} mb={0.5} gap={0.5} alignItems="flex-end">
                        <Typography>タグ</Typography>
                        <Typography variant="body2" flexGrow={1}>
                            （連携済みのSlackチャンネルに通知できます）
                        </Typography>
                        <Button variant="outlined" onClick={() => setOpenSlackHelp(true)}>
                            Slackの設定方法を見る
                        </Button>
                    </FlexBox>
                    <TagSelector
                        formText={props.form.tags}
                        setCache={props.setCache}
                        existingTags={props.existingTags ?? []}
                    />
                    <FlexBox mt={1.5} mb={0.5} justifyContent="space-between">
                        <Typography>本文</Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    checked={wysiwyg}
                                    tabIndex={-1}
                                    onChange={toggleWysiwyg}
                                />
                            }
                            componentsProps={{
                                typography: { variant: 'body2' },
                            }}
                            label="リッチエディタ"
                        />
                    </FlexBox>
                    {wysiwyg ? (
                        <TiptapEditor formText={props.form.content} />
                    ) : (
                        <FormTextField
                            formText={props.form.content}
                            fullWidth
                            multiline
                            minRows={10}
                            maxRows={30}
                        />
                    )}
                    <RemainingCharsIndicator
                        text={props.form.content.value}
                        maxBytes={CONTENT_MAX_BYTES}
                    />
                </Box>
                <Box flex="0 0 50%">
                    <Box maxWidth="md">
                        <Typography>プレビュー</Typography>
                        <Typography mt={2} variant="h4">
                            {props.form.title.value}
                        </Typography>
                        <ArticleMarkdown source={previewContent} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const RemainingCharsIndicator: React.FC<{ text: string; maxBytes: number }> = ({
    text,
    maxBytes,
}) => {
    const theme = useTheme();
    const bytes = countBytes(text);
    const bytesLeft = maxBytes - bytes;
    const approxCharsLeft = Math.floor(bytesLeft / 3);

    const threshold = maxBytes > 255 ? maxBytes * 0.05 : 10;
    if (bytesLeft > threshold) {
        return null;
    }

    const color = bytesLeft >= 0 ? theme.palette.warning.main : theme.palette.error.main;

    return (
        <Box textAlign="right">
            <Typography textAlign="right" variant="button" sx={{ color }}>
                あと約{approxCharsLeft}文字
            </Typography>
        </Box>
    );
};

const SlackHelpDialog: React.FC<{ open: boolean; handleClose: () => void }> = (props) => {
    const theme = useTheme();
    return (
        <Dialog
            slotProps={{
                backdrop: {
                    sx: {
                        // zIndex: 2000,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(4px)',
                    },
                },
            }}
            open={props.open}
        >
            <DialogTitle>
                <FlexBox gap={0.5}>
                    <HelpOutlineIcon />
                    ノートの投稿をSlack連携するには
                </FlexBox>
            </DialogTitle>
            <DialogContent
                sx={{
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <Typography mt={2}>
                    1.
                    連携したいSlackチャンネルで「@Hitonowa」にメンションします。途中まで入力すると候補が出てくるので、それを選択してメッセージを投稿してください。
                </Typography>
                <Box my={1} textAlign="center">
                    <img width="400" src="/images/slack/mention.png" />
                </Box>
                <Typography>
                    2.
                    連携前のチャンネルだとダイアログが表示されます。「チャンネルに追加する」で追加します。
                </Typography>
                <Box my={1} textAlign="center">
                    <img width="400" src="/images/slack/invite.png" />
                </Box>
                <Typography>3. こういうメッセージが表示されたら成功です。</Typography>
                <Box my={1} textAlign="center">
                    <img width="500" src="/images/slack/completed.png" />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={props.handleClose}
                >
                    閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
};
