import { Alert, Box, Button, useTheme } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes, GoogleAuthProvider, getAuth, signInWithRedirect } from 'firebase/auth';
import React, { useEffect } from 'react';
import { firebaseApp } from '../../App';
import { getRandomInt } from '../../lib/Random';
import { useGateway } from '../Context';
import { Logo } from '../Logo';

export const SignIn: React.FC<React.PropsWithChildren<unknown>> = () => {
    const theme = useTheme();
    const gateway = useGateway();
    useEffect(() => {
        void gateway.wakeUp();
    }, [gateway]);
    return (
        <Box
            display="flex"
            sx={{
                height: '100vh',
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: '50%',
                    padding: theme.spacing(0, 2),
                    textAlign: 'center',
                }}
            >
                {gateway.signOutMessage !== null && (
                    <Alert
                        sx={{
                            width: '100%',
                            margin: theme.spacing(0, 2, 4),
                        }}
                    >
                        {gateway.signOutMessage}
                    </Alert>
                )}
                <Box mb={4}>
                    <Logo size={200} />
                </Box>
                <Button
                    sx={{ backgroundColor: '#555' }}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleSignIn}
                >
                    Googleでログイン
                </Button>
            </Box>
            <Box
                sx={{
                    width: '50%',
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundColor: theme.palette.primary.main,
                    backgroundImage: `url("/images/office/${getRandomInt(8)}.jpg")`,
                    backgroundPositionX: 'center',
                    backgroundSize: 'cover',
                    '&:after': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        content: '""',
                        display: 'block',
                        backgroundColor: 'rgba(128, 128, 128, .4)',
                        backgroundImage: `radial-gradient(${theme.palette.nav.background} 20%, transparent 0), radial-gradient(${theme.palette.nav.background} 20%, transparent 0)`,
                        backgroundPosition: '0 0, 10px 10px',
                        backgroundSize: '4px 4px',
                    },
                }}
            ></Box>
        </Box>
    );
};

// ログイン用のポップアップウィンドウが閉じられた時のエラーは無視する
// https://firebase.google.com/docs/reference/js/auth.md#autherrorcodes
const ignoreErrors: string[] = [
    AuthErrorCodes.EXPIRED_POPUP_REQUEST,
    AuthErrorCodes.POPUP_CLOSED_BY_USER,
];

const handleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(getAuth(firebaseApp), provider).catch((e: unknown) => {
        if (e instanceof FirebaseError) {
            if (ignoreErrors.includes(e.code)) {
                return;
            }
            throw e;
        }
    });
};
