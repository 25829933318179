import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useEmptyForm } from '../../../../../lib/Form';
import { usePublishTodoItem } from '../../../../../queries/todoAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../../itemList/Context';
import { useTodoItemContext } from '../Context';

export const PublishTodoItemDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { item } = useTodoItemContext();
    const form = useEmptyForm();
    const mutation = usePublishTodoItem(item.id);
    return (
        <>
            <DialogTitle>「{item.title}」を公開する</DialogTitle>
            <DialogContent>
                <Typography>やることを公開すると、</Typography>
                <Typography mt={1}>・対象者に「登録されました」のSlack通知が飛びます</Typography>
                <Typography mt={0.5}>
                    ・対象者のヒトノワ画面上のやること一覧に表示されるようになります
                </Typography>
                <Typography mt={2}>
                    一度公開したやることを準備中に戻すことも可能ですが、混乱を招く恐れがあるため基本的にはしないでください。
                </Typography>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="公開して対象者に通知する"
                cancelLabel="公開せずに閉じる"
                errorMessage="公開状態の変更に失敗しました"
                onSubmit={mutation.mutateAsync}
                closeDialog={closeDialog}
            />
        </>
    );
};
