import type { Privilege } from '@spec/Grants';
import type { TalentId } from '@spec/Talent';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useGateway } from '../components/Context';
import { invalidateMe } from './me';
import { queryKey } from './queryKey';

export const useGrants = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.grants],
        queryFn: () => gateway.fetchGrants(),
    });
};

export const invalidateGrants = (queryClient: QueryClient) =>
    queryClient
        .invalidateQueries({ queryKey: [queryKey.grants] })
        .then(() => invalidateMe(queryClient));

export const useGrantPrivileges = (talentId: TalentId) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (privileges: Set<Privilege>) => {
            await Promise.all(
                [...privileges].map((privilege) => gateway.grantPrivilege({ talentId, privilege }))
            );
        },
        onSettled: () => invalidateGrants(queryClient),
    });
};

export const useRevokePrivilege = (talentId: TalentId) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (privilege: Privilege) => gateway.revokePrivilege({ talentId, privilege }),
        onSettled: () => invalidateGrants(queryClient),
    });
};
