import React from 'react';
import { ApplicationError, reportError } from '../../Errors';
import { useFirebaseUserContext } from '../Context';
import { ContextualDialog, DialogComponents } from '../StableDialog';
import { NoMenuLayout } from '../layout/NoMenuLayout';
import { ConfirmDialog } from './ConfirmDialog';
import { ContextProvider, DialogMode } from './Context';
import { SignUpFormContent } from './SignUpForm';

export const SignUp: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { firebaseUser } = useFirebaseUserContext();
    if (firebaseUser.email === null) {
        reportError(new ApplicationError('Unexpected null firebase user.'));
        return null;
    }
    return (
        <NoMenuLayout>
            <ContextProvider>
                <SignUpFormContent email={firebaseUser.email} />
                <ContextualDialog components={dialogComponents} />
            </ContextProvider>
        </NoMenuLayout>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    Confirm: ConfirmDialog,
};
