import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DownloadIcon from '@mui/icons-material/Download';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Typography,
} from '@mui/material';
import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import React, { memo, useEffect, useState } from 'react';
import { reportInfo } from '../../../Errors';
import { generateTalentsCsv } from '../../../domains/Talent';
import { removeCsvUrl } from '../../../lib/Csv';
import { useMeContext } from '../../../queries/me';
import { useTeamsContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { ExpandMoreIcon } from '../../Icons';

export const CsvDownload: React.FC<{ talents: Talent[] }> = (props) => {
    const { teams } = useTeamsContext();
    const { grants } = useMeContext();
    if (grants.downloadCsv !== true) {
        return null;
    }
    return (
        <Box mt={2}>
            <Accordion TransitionProps={{ mountOnEnter: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FlexBox>
                        <CloudDownloadIcon color="primary" />
                        <Box ml={1}>
                            <Typography variant="body2">HR用CSVダウンロード</Typography>
                        </Box>
                    </FlexBox>
                </AccordionSummary>
                <AccordionDetails>
                    <DownloadButton talents={props.talents} teams={teams} />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export const DownloadButton: React.FC<{ talents: Talent[]; teams: Team[] }> = memo((props) => {
    const { me } = useMeContext();
    const [csvUrl, setCsvUrl] = useState<string | null>(null);
    useEffect(() => {
        const now = new Date();
        const url = generateTalentsCsv(props.talents, props.teams, now);
        setCsvUrl(url);
        return () => {
            if (url !== null) {
                removeCsvUrl(url);
            }
        };
    }, [props.talents, props.teams]);
    return (
        <Box textAlign="center">
            <Alert severity="warning">
                この操作は「プライバシー情報閲覧」の権限を持つ人にだけ許可されています。情報の取り扱いには十分注意してください。
            </Alert>
            {csvUrl && (
                <Box mt={2}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="medium"
                        startIcon={<DownloadIcon />}
                        href={csvUrl}
                        download="talents.csv"
                        onClick={() => {
                            reportInfo(`${me?.hitonowaId} downloads talents CSV`);
                        }}
                    >
                        検索条件に合致する従業員の情報をCSVでダウンロードする
                    </Button>
                </Box>
            )}
        </Box>
    );
});
