import { MenuItem, TextField, type TextFieldProps } from '@mui/material';
import { ReactNode, useState } from 'react';

const NOT_SELECTED = 'none';

export const MultiSelect = <T,>(
    props: {
        values: T[];
        setItems: (v: string[]) => void;
        children: ReactNode;
    } & TextFieldProps
) => {
    const [open, setOpen] = useState(false);
    const { values, setItems, children, ...rest } = props;
    return (
        <TextField
            {...rest}
            select
            SelectProps={{
                autoWidth: true,
                multiple: true,
                displayEmpty: true,
                open,
                onOpen: () => setOpen(true),
                onClose: () => setOpen(false),
                renderValue: (selected) => {
                    const text = [...(selected as string[])].sort().join(', ');
                    return <>{text === '' ? '指定なし' : text}</>;
                },
            }}
            value={props.values}
            onChange={(e) => {
                const selected = e.target.value as unknown as string[];
                const shouldClear = selected.includes(NOT_SELECTED);
                if (shouldClear) {
                    setOpen(false);
                    props.setItems([]);
                } else {
                    props.setItems(selected);
                }
            }}
        >
            <MenuItem value={NOT_SELECTED}>指定なし</MenuItem>
            {props.children}
        </TextField>
    );
};
