import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Talent } from '@spec/Talent';
import { FlexBox } from '../../FlexBox';
import { SubTitle } from '../../PageTitle';

export const PersonalEventHistory: React.FC<{ talent: Talent }> = (props) => {
    const personalEvents = props.talent.personalEventHistory ?? [];
    if (personalEvents.length === 0) {
        return null;
    }
    return (
        <Box>
            <SubTitle mt={1} title="過去の経歴" />
            <Box mt={1}>
                <PersonalEventsTable talent={props.talent} />
            </Box>
        </Box>
    );
};

const PersonalEventsTable: React.FC<{ talent: Talent }> = (props) => {
    const personalEvents = props.talent.personalEventHistory ?? [];
    return (
        <Box>
            {personalEvents.map((v, i) => (
                <FlexBox key={i} mx={2} mb={0.5}>
                    <Box sx={{ width: '7rem' }}>
                        <Typography variant="body2">
                            {v.startYear}年{v.endYear !== null && <> 〜 {v.endYear}年</>}
                        </Typography>
                    </Box>
                    <Box flexGrow={1}>
                        <Typography variant="body2">{v.label}</Typography>
                    </Box>
                </FlexBox>
            ))}
        </Box>
    );
};
