import React, { createContext, ReactNode, useContext, useState } from 'react';
import { DialogContextProvider, useGenericDialogContext } from '../../../Context';

export type DialogMode = 'AddTodoItem';
export const useDialogContext = useGenericDialogContext<DialogMode>;

export interface FilterCondition {
    categoryId: number | null;
    showFinishedItem: boolean;
}

const FilterFormContext = createContext(
    {} as {
        condition: FilterCondition;
        updateCondition: (v: Partial<FilterCondition>) => void;
    }
);
const FilterFormContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const [condition, setCondition] = useState<FilterCondition>({
        categoryId: null,
        showFinishedItem: false,
    });
    const updateCondition = (v: Partial<FilterCondition>) => {
        setCondition((before) => {
            return { ...before, ...v };
        });
    };
    return (
        <FilterFormContext.Provider value={{ condition, updateCondition }}>
            {props.children}
        </FilterFormContext.Provider>
    );
};
export const useFilterFormContext = () => useContext(FilterFormContext);

export const ContextProvider: React.FC<{
    children: ReactNode;
}> = (props) => {
    return (
        <DialogContextProvider>
            <FilterFormContextProvider>{props.children}</FilterFormContextProvider>
        </DialogContextProvider>
    );
};
