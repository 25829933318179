import TitleIcon from '@mui/icons-material/AccountTree';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material';
import { Team, TeamId } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import { UseQueryResult } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { z } from 'zod';
import { findChildTeams } from '../../../domains/Organization';
import { isLeftTalent } from '../../../domains/Talent';
import { useStorageJson } from '../../../lib/Storage';
import { queryToArray } from '../../../queries';
import { useTalents } from '../../../queries/talent';
import { useTeamsContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { MinusSquareIcon, PlusSquareIcon } from '../../Icons';
import { PageTitle } from '../../PageTitle';
import { ROOT_TEAM_ID } from '../../TeamDrill';
import { TenantContent } from '../../TenantContent';
import { ContextProvider, ShowCodeContext } from './Context';
import { Partners } from './Partners';
import { TeamCard } from './TeamCard';

const TeamIdsSchema = z.object({
    teamIds: z.array(z.number()),
});

export const TeamList: React.FC = () => {
    const theme = useTheme();
    const maybeTalents = useTalents();
    const { teams } = useTeamsContext();
    const [showAbolished, setShowAbolished] = useState(false);
    const filteredTeams = showAbolished ? teams : teams.filter((v) => v.abolishedAt === null);
    const [defaultOpen, setDefaultOpen] = useState(false);
    const [updated, setUpdated] = useState(new Date().getTime());

    const [openTeamsIds, setOpenTeamsIds] = useStorageJson(
        'organization:openTeamIds',
        { teamIds: [ROOT_TEAM_ID] },
        TeamIdsSchema
    );
    const toggleTeamOpen = (teamId: number) => {
        setOpenTeamsIds('teamIds', (prev) => {
            if (prev.includes(teamId)) {
                return prev.filter((id) => id !== teamId);
            } else {
                return [...prev, teamId];
            }
        });
    };

    return (
        <ContextProvider>
            <Box>
                <PageTitle icon={TitleIcon} title="組織を見る" />
                <FlexBox ml={3} gap={theme.spacing(2)}>
                    <Button
                        size="medium"
                        onClick={() => {
                            setDefaultOpen(true);
                            setOpenTeamsIds(
                                'teamIds',
                                filteredTeams.map((v) => v.id)
                            );
                            setUpdated(new Date().getTime());
                        }}
                        startIcon={<PlusSquareIcon />}
                    >
                        すべて開く
                    </Button>
                    <Button
                        size="medium"
                        onClick={() => {
                            setDefaultOpen(false);
                            setOpenTeamsIds('teamIds', [ROOT_TEAM_ID]);
                            setUpdated(new Date().getTime());
                        }}
                        startIcon={<MinusSquareIcon />}
                    >
                        すべて閉じる
                    </Button>
                    <FlexBox ml={1} alignItems="flex-start">
                        <Box>
                            <ShowCodeLink />
                        </Box>
                        <Box flexGrow={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showAbolished}
                                        onChange={(e) => setShowAbolished(e.target.checked)}
                                        name="showAbolished"
                                        size="small"
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        廃止済みの組織も表示する
                                    </Typography>
                                }
                            />
                        </Box>
                    </FlexBox>
                </FlexBox>
                <TenantContent
                    carta={
                        <Box m={2}>
                            <Partners />
                        </Box>
                    }
                />
                <Box>
                    {findChildNodes(filteredTeams, null).map((v) => (
                        <Box key={v.id} mx={2} my={2}>
                            <Tree
                                key={updated}
                                root={v}
                                teams={filteredTeams}
                                maybeTalents={maybeTalents}
                                defaultOpen={defaultOpen}
                                openTeamsIds={new Set(openTeamsIds.teamIds)}
                                toggleTeamOpen={toggleTeamOpen}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
            <Outlet />
        </ContextProvider>
    );
};

const ShowCodeLink: React.FC = () => {
    const { showCode } = useContext(ShowCodeContext);
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={showCode.value}
                    onChange={(e) => showCode.setValue(e.target.checked)}
                    name="showCode"
                    size="small"
                />
            }
            label={<Typography variant="body2">組織コードを表示する</Typography>}
        />
    );
};

const Tree: React.FC<{
    root: Team;
    teams: Team[];
    maybeTalents: UseQueryResult<Talent[]>;
    defaultOpen: boolean;
    openTeamsIds: Set<number>;
    toggleTeamOpen: (teamId: number) => void;
}> = (props) => {
    const theme = useTheme();
    const { showCode } = useContext(ShowCodeContext);
    const memberAmount: number | null = props.maybeTalents.isPending
        ? null
        : countMembers(props.root.id, props.teams, queryToArray(props.maybeTalents));
    const [open, setOpen] = useState(props.openTeamsIds.has(props.root.id));
    const children = findChildNodes(props.teams, props.root.id);
    return (
        <Box>
            <FlexBox pl={1.5}>
                {children.length === 0 ? (
                    <Box sx={{ width: '24px' }}></Box>
                ) : (
                    <IconButton
                        size="small"
                        disabled={props.root.id === ROOT_TEAM_ID}
                        onClick={() => {
                            setOpen((prev) => !prev);
                            props.toggleTeamOpen(props.root.id);
                        }}
                    >
                        {open ? <MinusSquareIcon /> : <PlusSquareIcon />}
                    </IconButton>
                )}
                <TeamCard team={props.root} memberAmount={memberAmount} showCode={showCode.value} />
            </FlexBox>
            <Box
                sx={{
                    marginLeft: '22px',
                    borderLeft: `1px dotted ${theme.palette.text.disabled}`,
                }}
            >
                {open === true &&
                    children.map((v) => (
                        <Tree
                            key={v.id}
                            root={v}
                            teams={props.teams}
                            maybeTalents={props.maybeTalents}
                            defaultOpen={props.defaultOpen}
                            openTeamsIds={props.openTeamsIds}
                            toggleTeamOpen={props.toggleTeamOpen}
                        />
                    ))}
            </Box>
        </Box>
    );
};

const findChildNodes = (nodes: Team[], parentId: number | null): Team[] =>
    nodes.filter((v) => v.parentId === parentId);

const countMembers = (teamId: TeamId, teams: Team[], talents: Talent[]): number => {
    const teamIds = findChildTeams(teamId, teams).map((v) => v.id);
    const members = talents
        .filter((v) => !isLeftTalent(v))
        .filter((v) => v.isSuspended === false)
        .filter((v) => teamIds.includes(v.teamId));
    return members.length;
};
