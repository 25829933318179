import {
    Alert,
    Box,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useEmptyForm } from '../../../../../lib/Form';
import { usePreviewTodoItem, useUpdateTodoItem } from '../../../../../queries/todoAdmin';
import { SlackMrkDwn } from '../../../../Markdown';
import { ExternalLink } from '../../../../RouterLink';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { SubmitButton } from '../../../../SubmitButton';
import { useDialogContext } from '../../itemList/Context';
import {
    TodoItemFormContent,
    toEditTodoItemRequest,
    useTodoItemForm,
} from '../../itemList/dialogs/TodoItemForm';
import { useTodoItemContext } from '../Context';

export const EditTodoItemDialog: React.FC = () => {
    const theme = useTheme();
    const { closeDialog } = useDialogContext();
    const { item } = useTodoItemContext();

    const previewForm = useEmptyForm();
    const previewMutation = usePreviewTodoItem();

    const form = useTodoItemForm({
        todoCategoryId: item.todoCategoryId,
        title: item.title,
        description: item.description,
        deadline: item.deadline,
        reminders: item.reminders.map((v) => dayjs(v).format('YYYY-MM-DD HH:mm')).join('\n'),
    });
    const mutation = useUpdateTodoItem(item.id);
    return (
        <>
            <DialogTitle>やることの更新</DialogTitle>
            <DialogContent
                sx={{
                    height: '100vh',
                    backgroundColor: theme.palette.background.default,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            >
                <Box display="flex" mt={1} gap={2}>
                    <Box sx={{ width: '50%' }}>
                        <TodoItemFormContent form={form} />
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <DialogRow label="詳細のプレビュー">
                            <Paper variant="outlined" sx={{ p: 1 }}>
                                <SlackMrkDwn source={form.description.value} />
                            </Paper>
                        </DialogRow>
                        <DialogRow label="文法サンプル">
                            <Box mx={4}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>こう入力すると</TableCell>
                                            <TableCell>こう見える</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <SlackExample text="*太字*" />
                                        <SlackExample text="_斜体_" />
                                        <SlackExample text="~打ち消し~" />
                                        <SlackExample text="<https://URL|リンク文字列>" />
                                        <SlackExample text="<@ユーザID|ユーザ名>" />
                                        <SlackExample text="<#チャンネルID|チャンネル名>" />
                                    </TableBody>
                                </Table>
                                <Typography mt={1} variant="body2">
                                    ユーザIDやチャンネルIDはSlack上で確認してください。
                                </Typography>
                                <ExternalLink
                                    variant="body2"
                                    href="https://hitonowa.work/carta/notes/@nekoya/51181"
                                    icon
                                >
                                    IDの取得方法はノートの記事を参照してください
                                </ExternalLink>
                            </Box>
                        </DialogRow>
                    </Box>
                </Box>
                {previewForm.failed && (
                    <Box mt={2}>
                        <Alert severity="error">テスト通知に失敗しました</Alert>
                    </Box>
                )}
                {previewForm.succeeded && (
                    <Box mt={2}>
                        <Alert severity="success">あなたにテスト通知を送りました</Alert>
                    </Box>
                )}
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容でやることを更新する"
                cancelLabel="更新せずに閉じる"
                errorMessage="更新に失敗しました"
                additionalActions={
                    <SubmitButton
                        size="medium"
                        inProgress={previewForm.inProgress}
                        disabled={!form.canSubmit}
                        onClick={() => {
                            previewForm.send(
                                previewMutation.mutateAsync(toEditTodoItemRequest(form))
                            );
                        }}
                    >
                        自分にテスト通知する
                    </SubmitButton>
                }
                onSubmit={() => mutation.mutateAsync(toEditTodoItemRequest(form))}
                closeDialog={closeDialog}
            />
        </>
    );
};

const SlackExample: React.FC<{ text: string }> = (props) => {
    return (
        <TableRow>
            <TableCell>{props.text}</TableCell>
            <TableCell>
                <SlackMrkDwn source={props.text} />
            </TableCell>
        </TableRow>
    );
};
