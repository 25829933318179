import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Drawer,
    IconButton,
    Toolbar,
    useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStorageToggle } from '../../../lib/Storage';
import { RoutingPattern } from '../../../Routes';
import { useGateway } from '../../Context';
import { BeforeIcon, DirectionsRun, NextIcon } from '../../Icons';
import { Logo } from '../../Logo';
import { RouterLink } from '../../RouterLink';
import { TenantContent } from '../../TenantContent';
import { Carta } from './Carta';
import { NavContextProvider } from './Context';
import { NavItem } from './NavItem';
import { MENU_WIDTH, NavList } from './NavList';
import { Treasure2024 } from './Treasure2024';

const COLLAPSED_MENU_WIDTH = '56px';

export const Nav: React.FC<React.PropsWithChildren<unknown>> = () => {
    const gateway = useGateway();
    const theme = useTheme();
    const [collapse, toggleCollapse] = useStorageToggle('navigationCollapsed');
    const [showSignOutDialog, setShowSignOutDialog] = useState(false);
    const location = useLocation();
    const firstPath = location.pathname.split('/')[1];
    return (
        <Drawer
            variant="permanent"
            sx={{
                width: collapse ? COLLAPSED_MENU_WIDTH : MENU_WIDTH,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            }}
            PaperProps={{
                sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: collapse ? COLLAPSED_MENU_WIDTH : MENU_WIDTH,
                    overflowX: 'hidden', // disable horizontal scroll
                    margin: 0,
                    padding: 0,
                    backgroundColor: theme.palette.nav.background,
                    color: theme.palette.nav.text,
                },
            }}
        >
            <Toolbar
                variant="dense"
                sx={{
                    width: MENU_WIDTH,
                    background: theme.palette.background.default,
                }}
            >
                <Box mt={0.5} mb={-0.5} ml={5.5}>
                    <RouterLink to={RoutingPattern.index}>
                        <Logo size={90} />
                    </RouterLink>
                </Box>
            </Toolbar>
            <NavContextProvider collapse={collapse} firstPath={firstPath}>
                <TenantContent carta={<Carta />} treasure2024={<Treasure2024 />} />
                <NavList>
                    <NavItem
                        icon={DirectionsRun}
                        label="ログアウト"
                        onClick={() => setShowSignOutDialog(true)}
                    />
                </NavList>
                <Dialog open={showSignOutDialog} onClose={() => setShowSignOutDialog(false)}>
                    <DialogTitle sx={{ textAlign: 'center' }}>ログアウトしますか？</DialogTitle>
                    <DialogActions sx={{ px: 4, pb: 3, columnGap: 2 }}>
                        <Button variant="outlined" onClick={() => setShowSignOutDialog(false)}>
                            いいえ、利用を続けます
                        </Button>
                        <Button variant="contained" onClick={() => gateway.signOut()}>
                            はい、ログアウトします
                        </Button>
                    </DialogActions>
                </Dialog>
                <Box
                    sx={{
                        width: MENU_WIDTH,
                        display: 'flex',
                        alignItems: 'center',
                        margin: theme.spacing(0, 0.5, 6),
                    }}
                >
                    <IconButton
                        onClick={toggleCollapse}
                        size="large"
                        sx={{ color: theme.palette.nav.icon }}
                    >
                        {collapse ? <NextIcon /> : <BeforeIcon />}
                    </IconButton>
                    <Button
                        sx={{
                            color: 'inherit',
                            fontWeight: 400,
                        }}
                        onClick={toggleCollapse}
                    >
                        メニューを{collapse ? '開く' : '閉じる'}
                    </Button>
                </Box>
            </NavContextProvider>
        </Drawer>
    );
};
