import { Box, Button, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { TeamId, UpdateTeamRequest } from '@spec/Organization';
import React from 'react';
import {
    Form,
    FormTextField,
    Strict,
    useForm,
    useFormDate,
    useFormNumber,
    useFormText,
} from '../../../../lib/Form';
import { required } from '../../../../lib/Form/Validators';
import { useIsDeletableTeam, useUpdateTeam } from '../../../../queries/organization';
import { DateInput } from '../../../DateInput';
import { FootNote } from '../../../FootNote';
import { DialogActionButtons, DialogRow } from '../../../StableDialog';
import { TeamSelector } from '../../../TeamSelector';
import { WaitQuery } from '../../../WaitLoading';
import { useDialogContext, useSelectedTeamContext } from '../Context';

interface Elements {
    parentId: TeamId | null;
    code: string;
    name: string;
    establishedAt: Date | null;
}

const guard = (v: Elements): v is Strict<Elements> => v.establishedAt !== null;

const toUpdateTeamRequest = (form: Form<Elements>): UpdateTeamRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('Invalid form values');
    }
    return {
        ...args,
        code: args.code === '' ? null : args.code,
    };
};

export const EditDialog: React.FC = () => {
    const { setDialogMode, closeDialog } = useDialogContext();
    const { selectedTeam } = useSelectedTeamContext();
    if (selectedTeam.value === null) {
        throw Error('Team does not selected.');
    }
    const team = selectedTeam.value;
    const maybeIsDeletable = useIsDeletableTeam(team.id);

    const form = useForm<Elements>({
        parentId: useFormNumber(team.parentId),
        code: useFormText(team.code ?? ''),
        name: useFormText(team.name, [required]),
        establishedAt: useFormDate(team.establishedAt, [required]),
    });
    const mutation = useUpdateTeam(team.id);
    return (
        <>
            <DialogTitle>「{team.name}」を編集します</DialogTitle>
            <DialogContent>
                <DialogRow label="親となる組織">
                    <TeamSelector teamId={form.parentId} />
                    <FootNote>
                        権限の及ばない組織の下に移動すると、その後の編集はできなくなります
                    </FootNote>
                </DialogRow>
                <DialogRow label="組織コード">
                    <FormTextField autoFocus fullWidth formText={form.code} />
                </DialogRow>
                <DialogRow label="部署名">
                    <FormTextField fullWidth formText={form.name} />
                </DialogRow>
                <DialogRow label="設立日">
                    <DateInput formDate={form.establishedAt} />
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="組織情報を更新する"
                cancelLabel="キャンセル"
                errorMessage="更新に失敗しました"
                onSubmit={() => mutation.mutateAsync(toUpdateTeamRequest(form))}
                closeDialog={closeDialog}
            />
            <DialogContent>
                <Divider />
                <Box textAlign="center" my={2}>
                    {team.abolishedAt !== null ? (
                        <Button
                            variant="outlined"
                            size="medium"
                            onClick={() => {
                                setDialogMode('RestoreTeam');
                            }}
                        >
                            「{team.name}」を復帰する
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            size="medium"
                            onClick={() => {
                                setDialogMode('AbolishTeam');
                            }}
                        >
                            「{team.name}」を廃止する
                        </Button>
                    )}
                </Box>
                <Box textAlign="center" my={2}>
                    <WaitQuery query={maybeIsDeletable} size="medium">
                        {({ data }) =>
                            data ? (
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    onClick={() => {
                                        setDialogMode('DeleteTeam');
                                    }}
                                >
                                    「{team.name}」を削除する
                                </Button>
                            ) : (
                                <Typography variant="body2">
                                    「{team.name}」は削除できません
                                </Typography>
                            )
                        }
                    </WaitQuery>
                </Box>
            </DialogContent>
        </>
    );
};
