import { Box, Stack } from '@mui/material';
import { useSurveys } from '../../queries/survey';
import { DashboardIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { ContextualDialog, DialogComponents } from '../StableDialog';
import { TenantContent } from '../TenantContent';
import { AnnouncementList } from '../announcement/AnnouncementList';
import { SurveysContextProvider } from '../survey/Context';
import { ActiveSurveys } from '../survey/list';
import { ConfirmTodoItem } from '../todo/dialogs/ConfirmTodoItem';
import { AssignedTodoItems } from './AssignedTodoItems';
import { ContextProvider, DialogMode } from './Context';
import { Newcomers } from './Newcomers';
import { RecentNotes } from './RecentNotes';
import { ValueFeedbackAnnouncement } from './ValueFeedbackAnnouncement';

export const Dashboard = () => {
    return (
        <ContextProvider>
            <PageTitle icon={DashboardIcon} title="ダッシュボード" />
            <Stack spacing={2}>
                <TenantContent carta={<Carta />} treasure2024={<Treasure2024 />} />
            </Stack>
            <ContextualDialog components={dialogComponents} />
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    ConfirmTodoItem: ConfirmTodoItem,
};

const Carta = () => {
    const maybeSurveys = useSurveys();
    return (
        <>
            <AnnouncementList />
            <ValueFeedbackAnnouncement />
            <AssignedTodoItems />
            <SurveysContextProvider maybeSurveys={maybeSurveys}>
                <ActiveSurveys />
            </SurveysContextProvider>
            <Box display="flex" justifyContent="space-between" gap={4}>
                <Box flexBasis="50%">
                    <Newcomers />
                </Box>
                <Box flexBasis="50%">
                    <Box mt={1}>
                        <RecentNotes />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const Treasure2024 = () => {
    const maybeSurveys = useSurveys();
    return (
        <>
            <AnnouncementList />
            <AssignedTodoItems />
            <SurveysContextProvider maybeSurveys={maybeSurveys}>
                <ActiveSurveys />
            </SurveysContextProvider>
            <RecentNotes />
        </>
    );
};
