import { CircleTerm } from '@spec/Circle';
import dayjs from 'dayjs';

export const findCurrentTerm = (terms: CircleTerm[], now: Date): number | null => {
    const x = dayjs(now);
    const current = terms.find((v) => x.isBetween(v.beginAt, v.endAt, 'day', '[]'));
    if (current) {
        return current.id;
    }
    return null;
};
