import { DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useEmptyForm } from '../../../../../lib/Form';
import { useFinishTodoItem } from '../../../../../queries/todoAdmin';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../../itemList/Context';
import { useTodoItemContext } from '../Context';

export const CompleteTodoItemDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { item } = useTodoItemContext();
    const form = useEmptyForm();
    const mutation = useFinishTodoItem(item.id);
    return (
        <>
            <DialogTitle>「{item.title}」を公開終了する</DialogTitle>
            <DialogContent>
                <Typography>やることを公開終了すると、</Typography>
                <Typography mt={1}>
                    ・未完了の対象者に「管理者によって完了されました」のSlack通知が飛びます
                </Typography>
                <Typography mt={0.5}>・未完了の対象者のやること一覧から消えます</Typography>
                <Typography mt={2}>
                    申込締切が過ぎて、今からやっても受付できなくなった場合などに完了してください。
                </Typography>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="公開終了して対象者に通知する"
                cancelLabel="公開終了せずに閉じる"
                errorMessage="公開終了に失敗しました"
                onSubmit={mutation.mutateAsync}
                closeDialog={closeDialog}
            />
        </>
    );
};
