import {
    Box,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { DisabledTableRow } from '../../../../../Theme';
import { findBaseTeam } from '../../../../../domains/Organization';
import { fullName, isAvailableTalent } from '../../../../../domains/Talent';
import { findById } from '../../../../../lib/ArrayUtils';
import { useEmptyForm } from '../../../../../lib/Form';
import { useAssignTodoItems } from '../../../../../queries/todoAdmin';
import { useTalentsContext, useTeamsContext } from '../../../../Context';
import { FlexBox } from '../../../../FlexBox';
import { DialogActionButtons } from '../../../../StableDialog';
import { useDialogContext } from '../../itemList/Context';
import { useTodoItemContext } from '../Context';

export const PasteDialog: React.FC = () => {
    const theme = useTheme();
    const { closeDialog } = useDialogContext();
    const { item, assignments } = useTodoItemContext();
    const { talents } = useTalentsContext();
    const assignees = new Set(
        assignments.map((v) => v.talentId).map((v) => findById(v, talents).employment.employeeCode)
    );
    const form = useEmptyForm();
    const mutation = useAssignTodoItems(item.id);
    const [codes, setCodes] = useState<Set<string>>(new Set());
    const parse = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCodes(
            new Set(
                e.target.value
                    .replace('\r', '')
                    .split('\n')
                    .map((v) => v.trim())
                    .filter((v) => v !== '')
            )
        );
    }, []);
    const candidates = [...codes]
        .filter((v) => {
            const talent = talents.find((t) => t.employment.employeeCode === v);
            if (talent === undefined) {
                return false;
            }
            return isAvailableTalent(talent);
        })
        .filter((v) => !assignees.has(v));
    return (
        <>
            <DialogTitle>「{item.title}」の対象者追加</DialogTitle>
            <DialogContent
                sx={{
                    height: '100vh',
                    backgroundColor: theme.palette.background.default,
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                }}
            >
                <FlexBox mt={2} alignItems="flex-start" gap={4}>
                    <Box>
                        <Typography color="primary">社員番号を貼り付けて追加できます</Typography>
                        <Box my={1}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={20}
                                maxRows={30}
                                onChange={parse}
                            />
                        </Box>
                        <Typography variant="body2">
                            ※一行にひとつ社員番号を入れてください
                        </Typography>
                        <Typography variant="body2">
                            ※社員番号に合致しない行はスキップされます
                        </Typography>
                    </Box>
                    <Box flexGrow={1}>
                        <Typography color="primary">プレビュー</Typography>
                        <Box mt={1}>
                            <Typography variant="body2">
                                {candidates.length}人が追加対象になります。
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>社員番号</TableCell>
                                        <TableCell>氏名</TableCell>
                                        <TableCell>所属</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...codes].map((v) => (
                                        <Row key={v} employeeCode={v} disabled={assignees.has(v)} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </FlexBox>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="対象に追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="追加に失敗しました"
                onSubmit={() =>
                    mutation.mutateAsync({
                        employeeCodes: candidates,
                    })
                }
                closeDialog={closeDialog}
            />
        </>
    );
};

const Row: React.FC<{ employeeCode: string; disabled: boolean }> = (props) => {
    const { talents } = useTalentsContext();
    const { teams } = useTeamsContext();
    const t = talents.find((v) => v.employment.employeeCode === props.employeeCode);
    const disabled = props.disabled || t === undefined;
    const RowComponent = disabled ? DisabledTableRow : TableRow;

    if (t === undefined) {
        return (
            <DisabledTableRow>
                <TableCell>{props.employeeCode}</TableCell>
                <TableCell colSpan={2}>該当者が存在しません</TableCell>
            </DisabledTableRow>
        );
    }

    const team = findById(t.teamId, teams);
    const baseTeam = findBaseTeam(t, teams);

    if (isAvailableTalent(t) === false) {
        return (
            <DisabledTableRow>
                <TableCell>{props.employeeCode}</TableCell>
                <TableCell>{fullName(t)}（退職もしくは停止アカウント）</TableCell>
                <TableCell>
                    {baseTeam && `${baseTeam.name} > `}
                    {team.name}
                </TableCell>
            </DisabledTableRow>
        );
    }

    return (
        <Tooltip
            title="既に対象になっています"
            placement="top"
            followCursor={true}
            disableHoverListener={!props.disabled}
        >
            <RowComponent>
                <TableCell>{t.employment.employeeCode}</TableCell>
                <TableCell>{fullName(t)}</TableCell>
                <TableCell>
                    {baseTeam && `${baseTeam.name} > `}
                    {team.name}
                </TableCell>
            </RowComponent>
        </Tooltip>
    );
};
