import { useQuery } from '@tanstack/react-query';
import { useGateway } from '../components/Context';
import { queryKey } from './queryKey';

export const useApiReferences = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.apiReferences],
        queryFn: () => gateway.apiReference(),
    });
};
