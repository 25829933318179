import { SlackChannelsResponse } from '@spec/Slack';
import { useQuery } from '@tanstack/react-query';
import { useGateway } from '../components/Context';
import { queryKey } from './queryKey';

export const useSlackChannels = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.slack, 'channels'],
        queryFn: () =>
            gateway.get<SlackChannelsResponse>('/slack/channels').then((res) => res.channels),
    });
};

export const useAppInvitedSlackChannels = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.slack, 'channels', 'invited'],
        gcTime: 0, // do not cache Slack channels to immediately reflect the update
        queryFn: () =>
            gateway
                .get<SlackChannelsResponse>('/slack/channels/invited')
                .then((res) => res.channels),
    });
};
