import { DialogContent, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import { EditPersonalEventRequest } from '@spec/Talent';
import { Form, FormTextField, useForm, useFormText } from '../../../lib/Form';
import { numericOnly, zenToHan } from '../../../lib/Form/Filters';
import { LengthCounter } from '../../../lib/Form/LengthCounter';
import { maxLength, numberRange, numeric, required } from '../../../lib/Form/Validators';
import { FlexBox } from '../../FlexBox';
import { DialogRow } from '../../StableDialog';

const LABEL_MAX_LENGTH = 191;

interface Elements {
    label: string;
    startYear: string;
    endYear: string;
}
type PersonalEventHistoryForm = Form<Elements>;

export const usePersonalEventHistoryForm = (v: Elements) =>
    useForm<typeof v>({
        label: useFormText(v.label, [required, maxLength(LABEL_MAX_LENGTH)]),
        startYear: useFormText(v.startYear, [required, numeric, numberRange(1900, 2050)], {
            onCompositionEnd: [zenToHan, numericOnly],
        }),
        endYear: useFormText(v.endYear, [numeric, numberRange(1900, 2050)], {
            onCompositionEnd: [zenToHan, numericOnly],
        }),
    });

export const toEditPersonalEventRequest = (
    form: PersonalEventHistoryForm
): EditPersonalEventRequest => {
    const args = form.serialize();
    return {
        label: args.label,
        startYear: Number(args.startYear),
        endYear: args.endYear === '' ? null : Number(args.endYear),
    };
};

export const PersonalEventForm: React.FC<{ form: PersonalEventHistoryForm }> = (props) => {
    return (
        <DialogContent>
            <DialogRow label="所属など">
                <FlexBox gap={1}>
                    <Box flexGrow={1}>
                        <FormTextField autoFocus fullWidth formText={props.form.label} />
                    </Box>
                    <LengthCounter text={props.form.label.value} maxLength={LABEL_MAX_LENGTH} />
                </FlexBox>
            </DialogRow>
            <DialogRow label="開始年">
                <FormTextField
                    formText={props.form.startYear}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">年</InputAdornment>,
                    }}
                />
            </DialogRow>
            <DialogRow label="終了年（省略可）">
                <FormTextField
                    formText={props.form.endYear}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">年</InputAdornment>,
                    }}
                />
            </DialogRow>
        </DialogContent>
    );
};
